import React, { useRef, useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { welcomePageContent } from "../utils/constant";
import useMainState from "../Context/state/useMainState";
import heartIcon from "../assets/hearth-icon.svg";
import { useAppState } from "../Context";

function Welcome() {
  const {
    isDropdownOpen,
    setIsDropdownOpen,
    dropDownRef,
  } = useMainState("app");
  const { isFreeTrial } = useAppState("auth");

  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const dropDownRef = useRef(null);



  const handleClick = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  return (
    <>
      <Navbar
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        dropDownRef={dropDownRef}
      />
      <section className="h-screen w-full flex flex-col" onClick={handleClick}>
        <div className="relative h-[calc(100vh_-_109px)] overflow-y-auto">
          <div className="w-full max-w-[680px] px-5 py-9 text-center shadow-custom-2 rounded-md absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%]">
            <h1 className="text-4xl font-poppinsBold text-[#03254c] pb-4">
              {welcomePageContent.welcomeText}
            </h1>
            <p className="font-poppinsRegular text-xl px-5 pb-6">
              {welcomePageContent.tagline}
            </p>
            <div className="">
              <Link
                to={`${isFreeTrial ? "/examination?freetrial=true" : "/examination"}`}
                className="px-8 py-3 font-poppinsBold text-white border rounded bg-[#03254C] inline-block"
              >
                {welcomePageContent.buttonText}
              </Link>
            </div>
          </div>
        </div>

        <div className="py-2.5 px-4 border-t border-[#dedede] absolute bottom-0 left-1/2 transform -translate-x-[50%] w-full bg-[#f0f0f0]">
          <p className="text-sm font-poppinsMedium flex items-center justify-center">
            {" "}
            Developed with{" "}
            <img className="mx-1 h-4 w-4" src={heartIcon} alt="" /> by{" "}
            <a
              className="inline-block text-sm font-poppinsMedium ml-1"
              href="https://www.thirdrocktechkno.com"
              target="_blank"
            >
              {" "}
              Thirdrocktechkno
            </a>
          </p>
        </div>
      </section>
    </>
  );
}

export default Welcome;
