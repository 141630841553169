import React from "react";
import { BrowserRouter, Routes as Routing, Route } from "react-router-dom";
import Form from "./pages/Form";
import Login from "./pages/Login";
import Welcome from "./pages/Welcome";
import Profile from "./pages/Profile";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import { useAppState } from "./Context";
import { Navigate } from "react-router-dom";


function Routes() {
  const { getIsAuthenticated } = useAppState("auth");

  return (
    <BrowserRouter>
      <Routing>
        {/* <Route path="/" element={<Login />} /> */}
        <Route
          path="/"
          element={
            getIsAuthenticated() ? (
              <Navigate to="/welcome" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/login" element={<Login />} />

        {/* Protected routes */}
        <Route exact element={<ProtectedRoutes />}>
          <Route path="/examination" element={<Form />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Routing>
    </BrowserRouter>
  );
}

export default Routes;
