import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import Select from "react-select";
import countryList from "react-select-country-list";
import ScanOFeLogo from "../assets/ScanOFeMainLogo.png";
import { useNavigate, useParams } from "react-router-dom";
import useMainState from "../Context/state/useMainState";
import heartIcon from "../assets/hearth-icon.svg";
import { confirmMessage } from "../utils/constant";

function Profile() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    specialization: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    countryISOCode: "",
  });
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [isShowClose, setIsShowClose] = useState(false);

  const changeHandler = (value) => {
    setValue(value);
    setFormData((prevData) => ({
      ...prevData,
      country: value.label,
      countryISOCode: value.value,
    }));
  };

  const { getProfileId } = useMainState("app");
  const navigate = useNavigate();
  const id = getProfileId();

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/scanofe-users/${id}`
      );
      const profileData = response.data.data.attributes;
      if (profileData) {
        (profileData.name && profileData.email && profileData.specialization ) ? setIsShowClose(true): setIsShowClose(false);
        setFormData({
          name: profileData.name,
          email: profileData.email,
          website: profileData.website,
          specialization: profileData.specialization,
          streetAddress: profileData.streetAddress,
          city: profileData.city,
          state: profileData.state,
        });
        let showCountry = {
          value: profileData.countryISOCode,
          label: profileData.country,
        };
        setValue(showCountry);
      } else {
        setFormData({
          name: "",
          email: "",
          website: "",
          specialization: "",
          streetAddress: "",
          city: "",
          state: "",
          country: "",
          countryISOCode: "",
        });
      }
    } catch (error) {
      console.error("Error while fetching profile", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchProfileData();
    }
  }, [id]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!isShowClose || confirm(confirmMessage)) {
        const payload = { data: formData };
        const profileId = getProfileId();
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/scanofe-users/${profileId}`,
          payload
        );
        if (response.status === 200) {
          navigate("/welcome");
        }
      }
    } catch (error) {
      console.error("Error while submitting profile", error);
    }
  };

  return (
    <section className="w-full flex flex-col">
      <div className="relative overflow-y-auto h-screen" >
        <div className="flex flex-col justify-center w-full max-w-[512px] text-center absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%]">
          <div className="text-center pb-4">
            <a className="inline-block">
              <img className="w-72" src={ScanOFeLogo} alt="ScanOFe" />
            </a>
            <p className="text-[#03254c] font-poppinsMedium pt-1">
              To Make Fetal Diagnosis Easily Reachable
            </p>
          </div>

          <div className="shadow-custom-2 rounded-md px-5 py-5">
            <form className="" onSubmit={handleSubmit}>
              <div className="mb-3">
                <label
                  htmlFor="name"
                  className="text-black font-poppinsMedium text-lg block pb-0.5 text-left"
                >
                  Name
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full border border-[#bcbcbc] rounded-md py-1.5 px-2 text-base focus:border-[#909090]"
                  required
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="text-black font-poppinsMedium text-lg block pb-0.5 text-left"
                >
                  Email
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full border border-[#bcbcbc] rounded-md py-1.5 px-2 text-base focus:border-[#909090]"
                  required
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="name"
                  className="text-black font-poppinsMedium text-lg block pb-0.5 text-left"
                >
                  Website{" "}
                  <span className="text-gray-400 text-sm">(Optional)</span>
                </label>
                <input
                  type="text"
                  id="website"
                  name="website"
                  className="w-full border border-[#bcbcbc] rounded-md py-1.5 px-2 text-base focus:border-[#909090]"
                  placeholder="Enter your website"
                  value={formData.website}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="name"
                  className="text-black font-poppinsMedium text-lg block pb-0.5 text-left"
                >
                  Specialization
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  id="specialization"
                  name="specialization"
                  className="w-full border border-[#bcbcbc] rounded-md py-1.5 px-2 text-base focus:border-[#909090]"
                  required
                  placeholder="Enter your Specialization"
                  value={formData.specialization}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="address"
                  className="text-black font-poppinsMedium text-lg block pb-0.5 text-left"
                >
                  Street Address
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  id="streetAddress"
                  name="streetAddress"
                  required
                  className="w-full border border-[#bcbcbc] rounded-md py-1.5 px-2 text-base focus:border-[#909090] resize-none"
                  placeholder="Write your street address here..."
                  value={formData.streetAddress}
                  onChange={handleChange}
                />
              </div>
              <div className="flex space-x-4">
                <div className="mb-3">
                  <label
                    htmlFor="name"
                    className="text-black font-poppinsMedium text-lg block pb-0.5 text-left"
                  >
                    City
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    className="w-full border border-[#bcbcbc] rounded-md py-1.5 px-2 text-base focus:border-[#909090]"
                    placeholder="Enter your city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="name"
                    className="text-black font-poppinsMedium text-lg block pb-0.5 text-left"
                  >
                    State
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    className="w-full border border-[#bcbcbc] rounded-md py-1.5 px-2 text-base focus:border-[#909090]"
                    placeholder="Enter your state"
                    value={formData.state}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="name"
                  className="text-black font-poppinsMedium text-lg block pb-0.5 text-left"
                >
                  Country
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <Select
                  name="country"
                  id="country"
                  className="country"
                  options={options}
                  value={value}
                  onChange={changeHandler}
                  placeholder="Select a country"
                />
              </div>
              {!isShowClose && (
                <button
                  type="submit"
                  className="px-4 py-2.5 text-white border rounded bg-[#03254C]"
                >
                  Save Details
                </button>
              )}
              {isShowClose && (
                <div className="flex justify-end space-x-4 pt-2">
                  <button
                    type="submit"
                    className="px-4 py-2.5 text-white border rounded bg-[#03254c]"
                  >
                    Update Details
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2.5 text-white border rounded bg-[#566997]"
                    onClick={() => navigate(-1)}
                  >
                    Close
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 left-0 w-full bg-[#f0f0f0] py-2.5 px-4 border-t border-[#dedede]">
        <p className="text-sm font-poppinsMedium flex items-center justify-center">
          {" "}
          Developed with <img
            className="mx-1 h-4 w-4"
            src={heartIcon}
            alt=""
          />{" "}
          by{" "}
          <a
            className="inline-block text-sm font-poppinsMedium ml-1"
            href="https://www.thirdrocktechkno.com"
            target="_blank"
          >
            {" "}
            Thirdrocktechkno
          </a>
        </p>
      </div>
    </section>
  );
}

export default Profile;
