import React from "react";

const DynamicTable = ({ data }) => {
  let object = data[0].attributes
  // Extract unique keys from the object
  const keys = Object.keys(object);

  return (
    <div className="laptop-small:overflow-y-auto laptop-small:h-[calc(100vh_-_170px)] h-full laptop-big:w-2/5 laptop-small:w-1/2 w-full">
      <table className="w-full text-sm text-left rtl:text-right ">
        <thead className="font-poppinsLight text-[#03254c] bg-[#eff1ff] laptop-small:sticky laptop-small:top-0">
          <tr>
            {keys.map((key) => (
              <th className="px-6 py-3" key={key.replace(/\bC_/g, "").replace(/\bMinus_/g, "- ").replace(/\bPlus_/g, "+ ").replace(/\bC_|_Point_/g, ".")}>
                {key.replace(/\bC_/g, "").replace(/\bC_|_Point_/g, ".").replace(/\bMinus_/g, "- ").replace(/\bPlus_/g, "+ ")}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
        {data.map((item, rowIndex) => (
          <tr className="bg-white border-b" key={rowIndex}>
            {keys.map((key) => (
              <td className="px-6 py-4" key={key}>{item.attributes[key]}</td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicTable;
