import React from "react";

export default function Tooltip({ text, children, className = "" }) {
  return (
    <div className={`relative inline-block group ${className}`}>
      {children}
      <span className="w-max opacity-0 invisible group-hover:opacity-100 group-hover:visible absolute z-10 bg-black text-white text-xs rounded py-1 px-2 bottom-full left-1/2 transform -translate-x-1/2 transition-opacity duration-300">
        {text}
      </span>
    </div>
  );
}
