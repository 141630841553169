import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PhoneInput, getActiveFormattingMask } from "react-international-phone";
import axios from "axios";
import OtpInput from "react-otp-input";
import ScanOFeLogo from "../assets/ScanOFeMainLogo.png";
import heartIcon from "../assets/hearth-icon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-international-phone/style.css";
import "./login.css";
import { useAppState } from "../Context";
import useMainState from "../Context/state/useMainState";

function Login() {
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [rowPhone, setRowPhone] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [isOtpSend, setIsOtpSend] = useState(false);
  const [otp, setOtp] = useState();
  const { setIsAuthenticated } = useAppState("auth");
  const { setProfileId } = useMainState("app");

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is already logged in
    const isActive = localStorage.getItem("isAuthenticated") === "true";

    if (isActive) {
      // Redirect to the welcome page if the user is already logged in
      navigate("/welcome");
    }
  }, [navigate]);

  const checkUserProfile = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/scanofe-users/${id}`
      );
      const profileData = response.data.data.attributes;
      const isProfileCreated = checkPropertiesNotNull(profileData);
      return isProfileCreated;
    } catch (error) {
      console.error(error, "Error while checking Profile data ");
    }
  };

  const checkPropertiesNotNull = (data) => {
    for (const key in data) {
      const value = data[key];
      if (key !== "website" && (value === null || value === "null")) {
        return false;
      }
    }
    return true;
  };

  const generateOTP = async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/otp/generate`,
        payload
      );
      if (response.status === 200) {
        setIsAuthenticated(true);
        // toast.success("Verification code sent successfully");
        // setIsOtpSend(true);
        setProfileId(response.data.id);
        const isProfileCreated = await checkUserProfile(response.data.id);
        if (isProfileCreated) {
          navigate("/welcome");
        } else {
          navigate("/profile");
        }
      }
    } catch (error) {
      console.error(error, "Error while generating otp");
    }
  };

  const verifyOTP = async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/otp/verify`,
        payload
      );
      if (response.status === 200) {
        setIsAuthenticated(true);
        toast.success(response.data.success);
        setTimeout(async () => {
          const isProfileCreated = await checkUserProfile(response.data.id);
          if (isProfileCreated) {
            navigate("/welcome");
          } else {
            navigate("/profile");
          }
        }, 2000);
        // navigate("/profile");
      }
    } catch (error) {
      console.error(error, "Error while verifying otp");
    }
  };

  const handlePhoneChange = (value, country) => {
    const rownumber = country && country.inputValue.split(" ");
    const number = rownumber[1] && rownumber[1].replace("-", "");
    setRowPhone(number);
    setCountryCode(country.country.dialCode);
    setMobileNumber(value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const mobileNumberWithoutDialCode = mobileNumber.replace(`+${countryCode}`, '');

    if (isOtpSend) {
      const payload = {
        data: {
          mobile_number: mobileNumberWithoutDialCode,
          country_code: countryCode,
          otp: otp,
        },
      };

      const data = verifyOTP(payload);
    } else {
      const payload = {
        data: {
          mobile_number: mobileNumberWithoutDialCode,
          country_code: countryCode,
        },
      };
      const data = generateOTP(payload);
    }
  };

  const handleEmail = (e) => {
    setEmailText(e.target.value);
  };
  const handlePassword = (e) => {
    setPasswordText(e.target.value);
  };
  return (
    <section className="h-screen w-full flex flex-col">
      <div className="h-[calc(100vh_-_41px)] relative overflow-y-auto">
        <div className="w-full max-w-[512px]  text-center absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%]">
          <div className="text-center pb-8">
            <a href="#" className="inline-block">
              <img className="w-72" src={ScanOFeLogo} alt="ScanOFe" />
            </a>
            <p className="text-[#03254c] font-poppinsMedium pt-1">
              To Make Fetal Diagnosis Easily Reachable
            </p>
          </div>

          <div className="shadow-custom-2 rounded-md px-5 py-8">
            <h1 className="text-[28px] font-poppinsMedium text-[#03254c] pb-4">
              Access to ScanOFe
            </h1>
            <form className="" onSubmit={handleFormSubmit}>
              <PhoneInput
                defaultCountry="in"
                value={phone}
                onChange={handlePhoneChange}
              />

              {isOtpSend && (
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  // renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "3rem",
                    paddingTop: "1.25rem"
                  }}
                  inputStyle={{
                    height: "3rem",
                    width: "3rem",
                    border: "1px solid gainsboro",
                    borderRadius: "5px",
                  }}
                  shouldAutoFocus
                />
              )}
              <button
                type="submit"
                className="px-4 py-3 text-white border rounded bg-[#03254C] inline-block mt-6"
              >
                {isOtpSend ? "Access to ScanOFe" : "Access to ScanOFe"}
                {/* Access to ScanOFe */}
              </button>
            </form>
        </div>
      </div>
	</div>
      
      <ToastContainer />
	  <div className="py-2.5 px-4 border-t border-[#dedede] absolute bottom-0 left-1/2 transform -translate-x-[50%] w-full bg-[#f0f0f0]">
		<p className="text-sm font-poppinsMedium flex items-center justify-center"> Developed with <img className="mx-1 h-4 w-4" src={heartIcon} alt="" /> by <a className="inline-block text-sm font-poppinsMedium ml-1" href="https://www.thirdrocktechkno.com" target="_blank"> Thirdrocktechkno</a></p>
	  </div>
    </section>
  );
}

export default Login;
