import { useState } from "react";

const useAuthState = () => {
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  const getIsAuthenticated = () => {
    return localStorage.getItem("isAuthenticated");
  };

  const setFreeTrial = () => {
    localStorage.setItem("isFreeTrial", true);
  };

  const getFreeTrial = () => {
    return localStorage.getItem("isFreeTrial");
  };


  const removeFreeTrial = () => {
    localStorage.removeItem("isFreeTrial");
  };

  const setIsAuthenticated = (isAuthenticated) => {
    if (isAuthenticated) {
      localStorage.setItem("isAuthenticated", isAuthenticated);
    } else {
      localStorage.clear();
    }
  };

  const getIsAuthenticationWithFreeTrial = () => {
    let isByPass;
    const authtenticated = localStorage.getItem("isAuthenticated");
    isByPass = authtenticated;
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const product = urlParams.get("InteractiveDemo");
    if (product) {
      setFreeTrial();
      setIsFreeTrial(true);
      isByPass = true;
    }
    return isByPass;
  };

  return {
    getIsAuthenticated,
    setIsAuthenticated,
    getIsAuthenticationWithFreeTrial,
    isFreeTrial,
    removeFreeTrial,
    getFreeTrial
  };
};

export default useAuthState;
