import { useRef, useState } from "react";
import axios from "axios";

const useMainState = () => {
  const [diagnosesModel, setDiagnosesModel] = useState({
    isOpenSidebar: false,
    isOpenDiscription: false,
  });

  const [syndromeModel, setSyndromeModel] = useState({
    isOpenSidebar: false,
    isOpenDiscription: false,
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropDownRef = useRef(null);

  const getProfileId = () => {
    return localStorage.getItem("profileId");
  };

  const setProfileId = (profileId) => {
    localStorage.setItem("profileId", profileId);
  };

  const getProfile = async () => {
    const profileId = getProfileId();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/scanofe-users/${profileId}`
      );
      const profileData = response.data.data.attributes;
      return profileData;
    } catch (error) {
      console.error(error, "Error while checking Profile data ");
    }
  };

  return {
    diagnosesModel,
    setDiagnosesModel,
    syndromeModel,
    setSyndromeModel,
    isDropdownOpen,
    setIsDropdownOpen,
    dropDownRef,
    getProfileId,
    setProfileId,
    getProfile,
  };
};

export default useMainState;
