import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
  Fetal_Biometry_AC,
  Fetal_Biometry_BD,
  Fetal_Biometry_EFW,
  Fetal_Biometry_FL,
  Fetal_Biometry_HC,
  Trans_Cerebellar_Diameter,
  DV,
  Uterine_Artery,
  Uterine_Artery_IP,
  Oligo_SLVP,
  Oligo_AFI,
  UA,
  MCA,
  CPR,
} from "../utils/tablesData";
import "./search.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function GenerateAllChart({ chart, setChart }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const keys = Object.keys(chart.value);
    for (let i = 0; i < keys.length; i++) {
      switch (keys[i]) {
        case "BPD":
          let BPD = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: Fetal_Biometry_BD.weeks,
            data: Object.keys(Fetal_Biometry_BD),
            obj: Fetal_Biometry_BD,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(BPD);
          break;
        case "HC":
          let HC = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: Fetal_Biometry_HC.weeks,
            data: Object.keys(Fetal_Biometry_HC),
            obj: Fetal_Biometry_HC,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(HC);
          break;
        case "AC":
          let AC = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: Fetal_Biometry_AC.weeks,
            data: Object.keys(Fetal_Biometry_AC),
            obj: Fetal_Biometry_AC,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(AC);
          break;
        case "FL":
          let FL = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: Fetal_Biometry_FL.weeks,
            data: Object.keys(Fetal_Biometry_FL),
            obj: Fetal_Biometry_FL,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(FL);
          break;
        case "EFW":
          let EFW = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: Fetal_Biometry_EFW.weeks,
            data: Object.keys(Fetal_Biometry_EFW),
            obj: Fetal_Biometry_EFW,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(EFW);
          break;
        case "TCD":
          let TCD = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: Trans_Cerebellar_Diameter.weeks,
            data: Object.keys(Trans_Cerebellar_Diameter),
            obj: Trans_Cerebellar_Diameter,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(TCD);
          break;
        case "DV":
          let DVs = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: DV.weeks,
            data: Object.keys(DV),
            obj: DV,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(DVs);
          break;
        case "Uterine":
          let Uterine = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: Uterine_Artery.weeks,
            data: Object.keys(Uterine_Artery),
            obj: Uterine_Artery,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(Uterine);
          break;
        case "SLVP":
          let OligoSLP = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: Oligo_SLVP.weeks,
            data: Object.keys(Oligo_SLVP),
            obj: Oligo_SLVP,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(OligoSLP);
          break;
        case "AFI":
          let OligoAFI = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: Oligo_AFI.weeks,
            data: Object.keys(Oligo_AFI),
            obj: Oligo_AFI,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(OligoAFI);
          break;
        case "UA":
          let UAData = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: UA.weeks,
            data: Object.keys(UA),
            obj: UA,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(UAData);
          break;
        case "MCA":
          let MCAData = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: MCA.weeks,
            data: Object.keys(MCA),
            obj: MCA,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(MCAData);
          break;
        case "CPR":
          let CPRData = {
            name: keys[i],
            pointValue: chart.value[keys[i]] ? chart.value[keys[i]] : null,
            lables: CPR.weeks,
            data: Object.keys(CPR),
            obj: CPR,
            week: chart.week,
          };
          chart.value[keys[i]] && graphObjectSet(CPRData);
          break;
        default:
          console.log("Unknown chart type:", chart.value);
      }
    }
  }, [chart]);

  useEffect((e) => {
    const close = (e) => {
      if (e.key === "Escape") {
        closeModel();
      }
    };

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const closeModel = () => {
    setChart({ ...chart, showChart: false });
  };
  const borderColors = [
    "rgba(255, 159, 64)",
    "rgba(54, 162, 235)",
    "rgba(75, 192, 192)",
  ];
  const backgroundColor = [
    "rgba(255, 159, 64, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(75, 192, 192, 0.2)",
  ];

  const graphObjectSet = (data) => {
    let datasets = data.data
      .filter((label) => label !== "weeks")
      .map((label, index) => ({
        label,
        data: data.obj[label],
        borderColor: borderColors[index % borderColors.length],
        backgroundColor: backgroundColor[index % backgroundColor.length],
      }));

    const dynamicPointIndex = data.lables.indexOf(data.week);
    const dynamicPointDataset = {
      label: data.name,
      backgroundColor: "red",
      borderColor: "red",
      data: Array(data.lables.length)
        .fill(null)
        .map((value, index) =>
          index === dynamicPointIndex ? data.pointValue : null
        ),
      pointBackgroundColor: "red",
      pointRadius: 5,
      pointHoverRadius: 10,
      showLine: true,
    };

    let allData = {
      labels: data.lables,
      datasets: datasets.concat(dynamicPointDataset),
    };

    setChartData((lastData) => [...lastData, allData]);
  };

  if (chartData.length > 0) {
    for (let i = 0; i < chartData.length; i++) {
      const label = chartData[i].datasets[3].label;
      const weeks = chartData[i].labels;
      if (label === "Uterine") {
        const c_95th = chartData[i].datasets[2].data;
        const maxC95th = Math.max(...c_95th);

        const formattedData = weeks.map((week, index) => ({
          x: week,
          y: Math.round(maxC95th),
        }));
        const newData = {
          label: "Improper Placentation",
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          fill: 2,
          data: formattedData,
          borderWidth: 0,
          radius: 0,
        };
        chartData[i].datasets.push(newData);
      } else if (label === "SLVP") {
        const c_95th = chartData[i].datasets[2].data;
        const maxC95th = Math.max(...c_95th);

        const formattedDataAbove = weeks.map((week, index) => ({
          x: week,
          y: Math.round(maxC95th) + 1,
        }));

        const formattedDataBelow = weeks.map((week, index) => ({
          x: week,
          y: 1,
        }));

        const newDataAbove = {
          label: "Polyhydramnios",
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          fill: 2,
          data: formattedDataAbove,
          borderWidth: 0,
          radius: 0,
        };

        const newDataBelow = {
          label: "Oligohydramnios",
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          fill: 0,
          data: formattedDataBelow,
          borderWidth: 0,
          radius: 0,
        };
        chartData[i].datasets.push(newDataAbove, newDataBelow);
      } else if (label === "UA") {
        const plus2sd = chartData[i].datasets[0].data;
        const maxPlus2sd = Math.max(...plus2sd);

        const formattedDataAbove = weeks.map((week, index) => ({
          x: week,
          y: Math.round(maxPlus2sd) + 1,
        }));

        const newDataAbove = {
          label: "> +2 SD",
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          fill: 0,
          data: formattedDataAbove,
          borderWidth: 0,
          radius: 0,
        };
        chartData[i].datasets.push(newDataAbove);
      } else if (label === "MCA" || label === "CPR") {
        const formattedDataBelow = weeks.map((week, index) => ({
          x: week,
          y: 0.5,
        }));

        const newDataBelow = {
          label: "< -2 SD",
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          fill: 2,
          data: formattedDataBelow,
          borderWidth: 0,
          radius: 0,
        };
        chartData[i].datasets.push(newDataBelow);
      }
    }
  }

  return (
    <>
      {chart.showChart && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1]"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="relative bg-white rounded-md shadow w-[calc(100%_-_60px)] h-[calc(100vh_-_60px)] px-5 py-5">
            <div className="absolute z-20 pr-5 pt-5 rounded-t top-0 right-0">
              <button
                type="button"
                className=""
                data-modal-hide="small-modal"
                onClick={closeModel}
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="overflow-y-auto h-[calc(100vh_-_150px)] flex flex-wrap mt-10 mb-10">
              {chartData.map((data, index) => {
                let options = {
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: data.datasets[3].label,
                    },
                  },
                  scales: {
                    x: {
                      display: true,
                      title: {
                        display: true,
                        text: "weeks",
                        color: "blue",
                      },
                    },
                    y: {
                      display: true,
                      title: {
                        display: true,
                        text: "mm",
                        color: "blue",
                      },
                    },
                  },
                };
                return (
                  <div
                    key={index}
                    className={`p-2 ${
                      chartData.length === 1
                        ? "w-full"
                        : "laptop-small:w-1/2 w-full"
                    }`}
                  >
                    <Line
                      className="shadow-custom-3"
                      options={options}
                      data={data}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GenerateAllChart;
