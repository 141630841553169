export function filterDiagnosesData(diagnosesData) {
  const data = {
    name: diagnosesData.name,
    abnormalities: diagnosesData?.attributes?.abnormalities?.data.map((abs) => {
      return {
        name: abs?.attributes?.name,
        syndromes: diagnosesData.attributes.syndromes.data
          .map((datas) => {
            const ad = abs.attributes.name;
            let abnormalitiesCheck =
              datas.attributes.relatedAbnormalities.data.some((data) => {
                return data.attributes.name === ad;
              });
            let diagnosesCheck = datas.attributes.relatedDiagnoses.data.some(
              (data) => {
                return data.attributes.name === diagnosesData.attributes.name;
              }
            );

            let ifDiagnosesNotInAbnormalityCheck =
              datas.attributes.ifDiagnosesNotInAbnormality.some((data) => {
                return (
                  data.abnormality.data?.attributes?.name == ad &&
                  data.diagnosis.data.attributes.name ==
                    diagnosesData.attributes.name
                );
              });

            if (
              abnormalitiesCheck &&
              diagnosesCheck &&
              !ifDiagnosesNotInAbnormalityCheck
            ) {
              return {
                name: datas?.attributes?.name,
                slug: datas?.attributes?.slug,
                id: datas?.id,
              };
            }
          })
          .filter((data) => data !== undefined),
      };
    }),
  };
  return data;
}

export function getUniqueValues(array1, array2) {
  const array1Set = new Set(array1.map((item) => JSON.stringify(item)));
  const uniqueValues = array2.filter(
    (item) => !array1Set.has(JSON.stringify(item))
  );
  return uniqueValues;
}
