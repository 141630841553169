import React from "react";
import { useAppState } from "../Context";
import { Navigate, Outlet } from "react-router-dom";

function ProtectedRoutes() {
  const { getIsAuthenticated, getIsAuthenticationWithFreeTrial } = useAppState("auth");
  
  return getIsAuthenticationWithFreeTrial() ? <Outlet /> : <Navigate to="/login" />;
}

export default ProtectedRoutes;
