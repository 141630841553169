import React, { useState, useEffect } from "react";
import axios from "axios";
import { filterDiagnosesData } from "../utils/myFunction";
import "./search.css";
import {
  nuchalFoldThickeningDescription,
  textData,
  ventriculomegalyDdescription,
  clickableDiagnoses,
} from "../utils/constant";
import useAuthState from "../Context/state/useAuthState";

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader-spinner"></div>
    </div>
  );
};

const Checkbox = ({
  hit,
  checked,
  onCheckboxChange,
  isSyndrome,
  setIsOpenModel,
  isOpenModel,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [syndromeData, setSyndromeData] = useState(null);
  const [isSyndromeInfo, setIsSyndromeInfo] = useState(false);
  const [syndromeInfo, setSyndromeInfo] = useState(null);
  const [diagnosesInfo, setDiagnosesInfo] = useState(null);
  const [investigationInfo, setInvestigationInfo] = useState(false);
  const [syndromeInvestigationInfo, setSyndromeInvestigationInfo] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const { getFreeTrial } = useAuthState("app");

  let descriptionSyndrome =
    (syndromeData || syndromeInfo) &&
    (syndromeData || syndromeInfo)?.descriptionAndDefinition?.replace(
      /\*\*(.*?)\*\*/g,
      '<span className="font-bold">$1</span>'
    );

  // const closeDescriptionModel = () => {
  //   setIsOpenModel({ ...isOpenModel, isOpenDiscription: false });
  // };
  // const openDescriptionModel = () => {
  //   setIsOpenModel({ ...isOpenModel, isOpenDiscription: true });
  // };
  // useEffect(() => {
  //   if (isOpenModel.isOpenDiscription === true) {
  //     const closeSidebar = (e) => {
  //       if (e.key === "Escape") {
  //         closeModal();
  //       }
  //     };
  //     window.addEventListener("keydown", closeSidebar);
  //     return () => window.removeEventListener("keydown", closeSidebar);
  //   }
  // }, [isOpenModel.isOpenDiscription, isOpenModel.isOpenSidebar]);

  const fetchData = async () => {
    setLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_API_KEY}/syndromess/${hit.objectID}?populate=abnormalitiesDetectableByUltrasound.Data,otherPossibleAssociations.Data,investigations.data`
    );

    const descriptionAndDefinition =
      response.data?.data?.attributes?.descriptionAndDefinition;
    const abnormalitiesDetectableByUltrasound =
      response.data?.data?.attributes?.abnormalitiesDetectableByUltrasound;
    const otherPossibleAssociations =
      response.data?.data?.attributes?.otherPossibleAssociations;

    if (
      descriptionAndDefinition ||
      abnormalitiesDetectableByUltrasound ||
      otherPossibleAssociations
    ) {
      setShowModal(true);
      setSyndromeData(response.data.data.attributes);
      setLoading(false);
    } else {
      setShowModal(false);
    }
  };

  const fetchDataByDiagnosesName = async (name) => {
    setLoading(true);
    const resonse = await axios.get(
      `${process.env.REACT_APP_API_KEY}/diagnosess?filters[name][$eq]=${name}&populate=abnormalities,syndromes.relatedDiagnoses,syndromes.relatedAbnormalities,syndromes.ifDiagnosesNotInAbnormality,syndromes.ifDiagnosesNotInAbnormality.diagnosis,syndromes.ifDiagnosesNotInAbnormality.abnormality,syndromes.bodyParts,bodyParts`
    );
    return resonse;
  };

  const fetchDataBySyndromeName = async (name) => {
    setLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_API_KEY}/syndromess?filters[name][$eq]=${name}&populate=abnormalitiesDetectableByUltrasound.Data,otherPossibleAssociations.Data,investigations.data`
    );
    setSyndromeInfo(response.data.data[0].attributes);
    setInvestigationInfo(false);
    setLoading(false);
  };

  const openModal = async (name) => {
    setDiagnosesInfo(null);
    if (name === textData.VENTRICULOMEGALY) {
      let response = await fetchDataByDiagnosesName(textData.DIAGNOSESNAME);
      let diagnosesData = {
        ...response.data.data[0],
        name: name,
      };
      let data = filterDiagnosesData(diagnosesData);
      setDiagnosesInfo(data);
      setShowModal(true);
      setLoading(false);
      document.body.style.overflowY = "hidden";
    } else {
      !isSyndrome && setShowModal(true);
      isSyndrome &&
        !textData.NOTCLICKABLESYNDROMES.includes(hit.name) &&
        fetchData();
      document.body.style.overflowY = "hidden";
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setIsSyndromeInfo(false);
    setInvestigationInfo(false);
    setSyndromeInvestigationInfo(false);
    // closeDescriptionModel();
  };

  const handleChange = () => {
    onCheckboxChange();
  };

  const onItemClick = async (e) => {
    setLoading(true);
    setDiagnosesInfo(null);
    // if (e.target.innerHTML === textData.VENTRICULOMEGALY) {
    //   let response = await fetchDataByDiagnosesName(textData.DIAGNOSESNAME);
    //   let diagnosesData = {
    //     ...response.data.data[0],
    //     name: e.target.innerHTML,
    //   };
    //   let data = filterDiagnosesData(diagnosesData);
    //   setDiagnosesInfo(data);
    //   setInvestigationInfo(false);
    //   setLoading(false);
    // } else {
      await fetchDataBySyndromeName(e.target.innerHTML);
    // }
    setIsSyndromeInfo(true);
  };

  const onInvestigationClick = async () => {
    setInvestigationInfo(true);
  };

  const onSyndromeInvestigationClick = async () => {
    syndromeData.investigations.data.length !== 0 &&
      setSyndromeInvestigationInfo(true);
  };

  return (
    <>
      <div
        className={`flex flex-col sm:flex-row items-center pl-4 border border-gray-200 rounded gap-2 mb-2 mx-2 select-none ${
          !clickableDiagnoses.includes(hit.name) && !isSyndrome && getFreeTrial()
            ? "cursor-not-allowed pointer-events-none opacity-50"
            : ""
        }`}
        key={hit.objectID}
      >
        <div className="flex items-center">
          <input
            id={`bordered-checkbox-${hit.objectID}`}
            value={hit.objectID}
            type="checkbox"
            checked={checked}
            name="bordered-checkbox"
            className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            onChange={handleChange}
          />
        </div>
        <div className="flex-1 py-3 text-left">
          <label
            htmlFor={`bordered-checkbox-${hit.objectID}`}
            className="w-full sm:w-80 py-4 text-base laptop-small:text-lg font-medium text-gray-900 text-left"
          >
            {hit.name}
          </label>
        </div>

        <div className="mt-2 mb-2 mr-2">
          <button
            type="button"
            className={`bg-transparent hover:bg-[#03254C] text-[#03254C] font-semibold hover:text-white py-1 px-4 border border-[#03254C] hover:border-transparent rounded laptop-small:text-lg ${
              ((!isSyndrome && hit?.abnormalities?.length === 0) ||
                textData.NOTCLICKABLESYNDROMES.includes(hit.name)) &&
              "cursor-not-allowed"
            }`}
            onClick={(e) => {
              ((!isSyndrome && hit?.abnormalities?.length !== 0) ||
                isSyndrome) &&
                (e.preventDefault(), openModal(hit.name));
              // openDescriptionModel();
            }}
          >
            More Details
          </button>
        </div>
      </div>
      {loading && <Loader />} {/* Show loader when loading is true */}
      {showModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1]"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="bg-white rounded-md shadow w-[calc(100%_-_60px)] h-[calc(100vh_-_60px)] px-5 py-5 overflow-x-auto">
            <div
              className="absolute z-20 pr-[30px] pt-8 rounded-t top-0 right-0"
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
            >
              <button
                type="button"
                className="bg-white p-4"
                data-modal-hide="small-modal"
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="Header flex pb-1 mb-3 gap-x-16 border-b text-left laptop-small:w-auto w-full">
              <div className="left-header w-full">
                {isSyndrome ? (
                  <>
                    {diagnosesInfo === null && (
                      <div className="flex space-x-5 z-10">
                        <h3 className="pb-2 text-2xl font-medium text-gray-900">
                          {hit.name.charAt(0).toUpperCase() + hit.name.slice(1)}{" "}
                        </h3>

                        <button
                          className={`bg-transparent h-[2.2rem] mr-[2rem] text-[#03254C] font-semibold py-1 px-4 border  border-[#03254C] rounded ${
                            syndromeData.investigations.data.length !== 0
                              ? " hover:bg-[#03254C]  hover:text-white hover:border-transparent"
                              : "hidden"
                          }`}
                          type="button"
                          onClick={(e) => {
                            syndromeData.investigations.data.length !== 0 &&
                              onSyndromeInvestigationClick();
                          }}
                        >
                          Investigation
                        </button>
                      </div>
                    )}

                    {diagnosesInfo && (
                      <>
                        <div className="sticky">
                          <h3 className="font-poppinsMedium text-2xl">
                            Syndrome
                          </h3>
                          <h3 className="pb-2 text-xl font-medium text-gray-900">
                            {diagnosesInfo.name.charAt(0).toUpperCase() +
                              diagnosesInfo.name.slice(1)}
                          </h3>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="">
                      <h3 className="font-poppinsMedium text-2xl text-[#03254c] pb-0.5">
                        Diagnosis
                      </h3>
                      <h3 className="pb-1 text-lg font-poppinsMedium text-[#03254c]">
                        {hit.name.charAt(0).toUpperCase() + hit.name.slice(1)}
                      </h3>
                    </div>
                  </>
                )}
              </div>

              {(isSyndromeInfo || syndromeInvestigationInfo) && (
                <div className="middle-header pl-4 w-full">
                  {isSyndromeInfo && (
                    <div className="">
                      {diagnosesInfo ? (
                        <>
                          <div className="">
                            <h3 className="font-poppinsMedium text-2xl text-[#03254c] pb-0.5">
                              Syndrome
                            </h3>
                            <h3 className="pb-1 text-lg font-poppinsMedium text-[#03254c]">
                              {diagnosesInfo.name.charAt(0).toUpperCase() +
                                diagnosesInfo.name.slice(1)}
                            </h3>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="">
                            <h3 className="font-poppinsMedium text-2xl text-[#03254c] pb-0.5">
                              Syndrome
                            </h3>
                            <div className="flex justify-between items-center pb-1.5">
                              {syndromeInfo?.name && (
                                <h3
                                  title={
                                    syndromeInfo?.name.charAt(0).toUpperCase() +
                                    syndromeInfo?.name.slice(1)
                                  }
                                  className="text-lg font-poppinsMedium text-[#03254c]"
                                >
                                  {syndromeInfo?.name.charAt(0).toUpperCase() +
                                    syndromeInfo?.name.slice(1)}
                                </h3>
                              )}
                              <button
                                className="bg-transparent hover:bg-[#03254C] text-[#03254C] font-semibold hover:text-white py-1 px-4 mr-10 border border-[#03254C] hover:border-transparent rounded false"
                                type="button"
                                onClick={onInvestigationClick}
                              >
                                Investigation
                              </button>
                            </div>
                          </div>
                          <></>
                        </>
                      )}
                    </div>
                  )}
                  {syndromeInvestigationInfo && (
                    <div className="">
                      <div className="">
                        <h3 className="font-poppinsMedium text-2xl text-[#03254c] pb-2">
                          {"Investigation".charAt(0).toUpperCase() +
                            "Investigation".slice(1)}
                        </h3>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {investigationInfo && (
                <div className="right-header w-full">
                  {investigationInfo && (
                    <div className="">
                      <div className="">
                        <h3 className="font-poppinsMedium text-2xl text-[#03254c] pb-2">
                          {"Investigation".charAt(0).toUpperCase() +
                            "Investigation".slice(1)}
                        </h3>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="Body flex gap-x-16 text-left laptop-small:w-auto w-full">
              <div className="left-body w-full">
                {isSyndrome ? (
                  <>
                    {diagnosesInfo === null && (
                      <div className="overflow-y-auto test01 h-[calc(100vh_-_200px)] pr-5">
                        {syndromeData ? (
                          <>
                            {/* {syndromeData?.descriptionAndDefinition && (
                              <div className=" ">
                                <h2 className="text-lg font-poppinsSemiBold text-[#03254C] pb-1.5">
                                  Description And Definition
                                </h2>
                                <p
                                  className="text-gray-800 mb-4"
                                  dangerouslySetInnerHTML={{
                                    __html: descriptionSyndrome,
                                  }}
                                ></p>
                              </div>
                            )} */}

                            {syndromeData?.abnormalitiesDetectableByUltrasound && (
                              <div className=" ">
                                <h2 className="text-xl font-poppinsSemiBold text-[#03254C] pb-1.5">
                                  Ultrasound can also show :
                                </h2>

                                {syndromeData?.abnormalitiesDetectableByUltrasound?.Data?.map(
                                  (finding) => (
                                    <div key={finding.id} className="mb-4">
                                      <h4 className="font-medium mb-2 text-lg">
                                        {finding.Title}
                                      </h4>
                                      <ul className="list-disc ml-10 text-gray-700 ">
                                        {finding.Value.split("|").map(
                                          (item) => {
                                            if (item.trim().startsWith("@")) {
                                              let subItems = item
                                                .trim()
                                                .substring(1);
                                              return (
                                                <li
                                                  key={subItems}
                                                  className="mb-2 ml-5 list-[circle] text-lg"
                                                >
                                                  {subItems}
                                                </li>
                                              );
                                            } else {
                                              return (
                                                <li
                                                  key={item}
                                                  className="mb-2 text-lg"
                                                >
                                                  {item}
                                                </li>
                                              );
                                            }
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                            {syndromeData?.otherPossibleAssociations && (
                              <div className=" ">
                                <h2 className="text-xl font-poppinsSemiBold text-[#03254C] pb-1.5">
                                  Other Possible Findings
                                </h2>

                                {syndromeData?.otherPossibleAssociations?.Data?.map(
                                  (finding) => (
                                    <div key={finding.id} className="mb-4">
                                      <h4 className="font-medium mb-2 text-lg">
                                        {finding.Title}
                                      </h4>
                                      <ul className="list-disc ml-10 text-gray-700 ">
                                        {finding.Value.split("|").map(
                                          (item) => {
                                            if (item.trim().startsWith("@")) {
                                              let subItems = item
                                                .trim()
                                                .substring(1);
                                              return (
                                                <li
                                                  key={subItems}
                                                  className="mb-2 ml-5 list-[circle] text-lg"
                                                >
                                                  {subItems}
                                                </li>
                                              );
                                            } else {
                                              return (
                                                <li key={item} className="mb-2 text-lg">
                                                  {item}
                                                </li>
                                              );
                                            }
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {syndromeData?.heredity && (
                              <div className=" ">
                                <h2 className="text-xl font-poppinsSemiBold text-[#03254C] pb-1.5">
                                  Heredity
                                </h2>
                                <div className="mb-4 text-lg">
                                  {syndromeData?.heredity}
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="animate-pulse">
                            <div className="h-5 bg-gray-200 rounded w-64 mb-4"></div>

                            <div className="flex flex-col space-y-3">
                              <div className="h-4 bg-gray-200 rounded w-full"></div>
                              <div className="h-4 bg-gray-200 rounded w-full"></div>
                              <div className="h-4 bg-gray-200 rounded w-full"></div>
                            </div>

                            <div className="h-5 mt-10 bg-gray-200 rounded w-96 mb-4"></div>
                            <div className="h-5 bg-gray-200 rounded w-36 mb-4"></div>
                            <div className="flex flex-col space-y-3">
                              <div className="h-4 bg-gray-200 rounded w-44"></div>
                              <div className="h-4 bg-gray-200 rounded w-52"></div>
                              <div className="h-4 bg-gray-200 rounded w-80"></div>
                              <div className="h-4 bg-gray-200 rounded w-44"></div>
                              <div className="h-4 bg-gray-200 rounded w-80"></div>
                            </div>

                            <div className="h-5 mt-6 bg-gray-200 rounded w-36 mb-4"></div>
                            <div className="flex flex-col space-y-3">
                              <div className="h-4 bg-gray-200 rounded w-80"></div>
                              <div className="h-4 bg-gray-200 rounded w-44"></div>
                              <div className="h-4 bg-gray-200 rounded w-52"></div>
                              <div className="h-4 bg-gray-200 rounded w-64"></div>
                              <div className="h-4 bg-gray-200 rounded w-36"></div>
                              <div className="h-4 bg-gray-200 rounded w-72"></div>
                              <div className="h-4 bg-gray-200 rounded w-80"></div>
                              <div className="h-4 bg-gray-200 rounded w-44"></div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {diagnosesInfo && (
                      <>
                        <div className="overflow-y-auto test01 h-[calc(100vh_-_200px)] pr-5">
                          {/* <div className="">
                            <h2 className="text-xl font-poppinsSemiBold text-[#03254C] uppercase">
                              Description And Definition
                            </h2>
                            <p
                              className="text-gray-800 text-lg mb-4"
                              dangerouslySetInnerHTML={{
                                __html: ventriculomegalyDdescription,
                              }}
                            ></p>
                          </div> */}
                          {diagnosesInfo?.abnormalities.length !== 0 ? (
                            diagnosesInfo?.abnormalities.map((abnormality) => (
                              <div className="pb-5" key={abnormality.name}>
                                <h2 className="text-xl font-poppinsSemiBold text-[#03254C]">
                                  {abnormality.name}
                                </h2>
                                <ul className="text-gray-800 mb-4 ml-5">
                                  {abnormality?.syndromes?.map(
                                    (item, index) => (
                                      <li key={item.name} className="list-disc">
                                        <span className="mr-1 text-lg">
                                          {item.name}
                                        </span>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            ))
                          ) : (
                            <div className=" ">
                              <p className=" mx-2 flex justify-center items-center">
                                No data was found
                              </p>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="overflow-y-auto test01 h-[calc(100vh_-_200px)]">
                      {hit.name == "Nuchal Fold Thickening" && (
                        <div className="">
                          <h2 className="text-xl font-poppinsSemiBold text-[#03254C] pb-1.5">
                            Description And Definition
                          </h2>
                          <p
                            className="text-gray-800 mb-4 text-lg"
                            dangerouslySetInnerHTML={{
                              __html: nuchalFoldThickeningDescription,
                            }}
                          ></p>
                        </div>
                      )}
                      {hit?.abnormalities.length !== 0 ? (
                        hit?.abnormalities.map((abnormality) => (
                          <>
                            <div className="pb-4" key={abnormality.name}>
                              <h2 className="text-xl font-poppinsSemiBold text-[#03254C] pb-1.5">
                                {abnormality.name}
                              </h2>
                              <ul className="text-black underline text-lg pl-5 list-none">
                                {abnormality?.syndromes?.map((item, index) => (
                                  <li
                                    key={item.name}
                                    className="list-none mb-2"
                                  >
                                    <span
                                      className={`mr-1 px-2 text-lg hover:bg-[#03254c2b] ${
                                        textData.NOTCLICKABLESYNDROMES.includes(
                                          item.name
                                        )
                                          ? "cursor-not-allowed"
                                          : "cursor-pointer"
                                      }`}
                                      onClick={
                                        textData.NOTCLICKABLESYNDROMES.includes(
                                          item.name
                                        )
                                          ? null
                                          : onItemClick
                                      }
                                    >
                                      {item.name}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </>
                        ))
                      ) : (
                        <div className=" ">
                          <p className=" mx-2 flex justify-center items-center">
                            No data was found
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              {(isSyndromeInfo || syndromeInvestigationInfo) && (
                <div className="middle-body w-full">
                  {isSyndromeInfo && (
                    <div className="">
                      {diagnosesInfo ? (
                        <>
                          <div className="overflow-y-auto test01 h-[calc(100vh_-_200px)] pr-5">
                            {/* <div className="">
                            <h2 className="text-lg font-bold text-[#03254C] mt-2 mb-2 uppercase">
                              Description And Definition
                            </h2>
                            <p
                              className="text-gray-800 mb-4"
                              dangerouslySetInnerHTML={{
                                __html: ventriculomegalyDdescription,
                              }}
                            ></p>
                          </div> */}
                            {diagnosesInfo?.abnormalities.length !== 0 ? (
                              diagnosesInfo?.abnormalities.map(
                                (abnormality) => (
                                  <div
                                    className="  pb-5"
                                    key={abnormality.name}
                                  >
                                    <h2 className="text-xl font-bold text-[#03254C] mt-2 mb-2 uppercase">
                                      {abnormality.name}
                                    </h2>
                                    <ul className="text-gray-800 mb-4 ml-5">
                                      {abnormality?.syndromes?.map(
                                        (item, index) => (
                                          <li
                                            key={item.name}
                                            className="list-disc"
                                          >
                                            <span className="mr-1 text-lg">
                                              {item.name}
                                            </span>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                )
                              )
                            ) : (
                              <div className=" ">
                                <p className=" mx-2 flex justify-center items-center">
                                  No data was found
                                </p>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="overflow-y-auto test01 h-[calc(100vh_-_200px)] pr-5">
                            {/* {syndromeInfo?.descriptionAndDefinition && (
                            <div className=" ">
                              <h2 className="font-poppinsSemiBold text-[#03254C] pb-1.5">
                                Description And Definition
                              </h2>
                              <p
                                className="text-gray-800 mb-4"
                                dangerouslySetInnerHTML={{
                                  __html: descriptionSyndrome,
                                }}
                              ></p>
                            </div>
                          )} */}

                            {syndromeInfo?.abnormalitiesDetectableByUltrasound && (
                              <div className="">
                                <h2 className="text-xl font-poppinsSemiBold text-[#03254C] pb-1.5">
                                  Ultrasound can also show :
                                </h2>

                                {syndromeInfo?.abnormalitiesDetectableByUltrasound?.Data?.map(
                                  (finding) => (
                                    <div key={finding.id} className="mb-4">
                                      <h4 className="font-medium mb-2 text-lg">
                                        {finding.Title}
                                      </h4>
                                      <ul className="list-disc ml-10 text-gray-700 ">
                                        {finding.Value.split("|").map(
                                          (item) => {
                                            if (item.trim().startsWith("@")) {
                                              let subItems = item
                                                .trim()
                                                .substring(1);
                                              return (
                                                <li
                                                  key={subItems}
                                                  className="mb-2 ml-5 list-[circle] text-lg"
                                                >
                                                  {subItems}
                                                </li>
                                              );
                                            } else {
                                              return (
                                                <li
                                                  key={item}
                                                  className="mb-2 text-lg"
                                                >
                                                  {item}
                                                </li>
                                              );
                                            }
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                            {syndromeInfo?.otherPossibleAssociations && (
                              <div className=" ">
                                <h2 className="text-lg font-bold text-[#03254C] mt-2 mb-2 uppercase">
                                  Other Possible Findings
                                </h2>

                                {syndromeInfo?.otherPossibleAssociations?.Data?.map(
                                  (finding) => (
                                    <div key={finding.id} className="mb-4">
                                      <h4 className="font-medium mb-2 text-lg">
                                        {finding.Title}
                                      </h4>
                                      <ul className="list-disc ml-10 text-gray-700 ">
                                        {finding.Value.split("|").map(
                                          (item) => {
                                            if (item.trim().startsWith("@")) {
                                              let subItems = item
                                                .trim()
                                                .substring(1);
                                              return (
                                                <li
                                                  key={subItems}
                                                  className="mb-2 ml-5 list-[circle] text-lg"
                                                >
                                                  {subItems}
                                                </li>
                                              );
                                            } else {
                                              return (
                                                <li
                                                  key={item}
                                                  className="mb-2 text-lg"
                                                >
                                                  {item}
                                                </li>
                                              );
                                            }
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {syndromeInfo?.heredity && (
                              <div className=" ">
                                <h2 className="text-lg font-bold text-[#03254C] mt-2 mb-2 uppercase">
                                  Heredity
                                </h2>
                                <div className="mb-4 text-lg">
                                  {syndromeInfo?.heredity}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {syndromeInvestigationInfo && (
                    <div className="">
                      <div className="">
                        {syndromeData?.investigations?.data.length !== 0 ? (
                          <div className="overflow-y-auto h-[calc(100vh_-_200px)] pl-4">
                            {syndromeData?.investigations?.data.map(
                              (item, index) => (
                                <li
                                  key={item.attributes.name}
                                  className="list-disc"
                                >
                                  <span className="mr-1 text-lg">
                                    {item.attributes.name}
                                  </span>
                                </li>
                              )
                            )}
                          </div>
                        ) : (
                          <div className=" ">
                            <p className=" mx-2 flex justify-center items-center">
                              No Specifix Investagition
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {investigationInfo && (
                <div className="right-body w-full">
                  {investigationInfo && (
                    <div className="">
                      <>
                        <div className="">
                          {syndromeInfo?.investigations?.data.length !== 0 ? (
                            <div className="overflow-y-auto h-[calc(100vh_-_200px)]">
                              {syndromeInfo?.investigations?.data.map(
                                (item, index) => (
                                  <li
                                    key={item.attributes.name}
                                    className="list-disc"
                                  >
                                    <span className="mr-1 text-lg">
                                      {item.attributes.name}
                                    </span>
                                  </li>
                                )
                              )}
                            </div>
                          ) : (
                            <div className="">
                              <p className="mx-2 flex justify-center items-center">
                                No Specifix Investagition
                              </p>
                            </div>
                          )}
                        </div>
                      </>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* <div className="p-4 flex flex-row mx-4 h-[45rem] maxWidth">
                <div className="flex-1 overflow-y-auto pt-[1.1rem]">
                  {isSyndrome ? (
                    <>
                      {diagnosesInfo === null && (
                        <div className="sticky border-b flex space-x-5 z-10">
                          <h3 className="pb-2 text-2xl font-medium text-gray-900">
                            {hit.name.charAt(0).toUpperCase() +
                              hit.name.slice(1)}
                          </h3>

                          <button
                            className={`bg-transparent h-[2.2rem] mr-[2rem] text-blue-500 font-semibold py-1 px-4 border  border-blue-500 rounded ${
                              syndromeData.investigations.data.length !== 0
                                ? " hover:bg-blue-600  hover:text-white hover:border-transparent"
                                : "hidden"
                            }`}
                            type="button"
                            onClick={(e) => {
                              syndromeData.investigations.data.length !== 0 &&
                                onSyndromeInvestigationClick();
                            }}
                          >
                            Investigation
                          </button>
                        </div>
                      )}
                      {diagnosesInfo === null && (
                        <div className="overflow-y-auto max-h-[37rem]">
                          {syndromeData ? (
                            <>
                              {syndromeData?.descriptionAndDefinition && (
                                <div className=" ">
                                  <h2 className="text-lg font-bold text-blue-600 mt-2 mb-2 uppercase">
                                    Description And Definition
                                  </h2>
                                  <p
                                    className="text-gray-800 mb-4"
                                    dangerouslySetInnerHTML={{
                                      __html: descriptionSyndrome,
                                    }}
                                  ></p>
                                </div>
                              )}

                              {syndromeData?.abnormalitiesDetectableByUltrasound && (
                                <div className=" ">
                                  <h2 className="text-lg font-bold text-blue-600 mt-2 mb-2 uppercase">
                                    Abnormalities Detectable By Ultrasound
                                  </h2>

                                  {syndromeData?.abnormalitiesDetectableByUltrasound?.Data?.map(
                                    (finding) => (
                                      <div key={finding.id} className="mb-4">
                                        <h4 className="font-medium mb-2">
                                          {finding.Title}
                                        </h4>
                                        <ul className="list-disc ml-10 text-gray-700 ">
                                          {finding.Value.split("|").map(
                                            (item) => {
                                              if (item.trim().startsWith("@")) {
                                                let subItems = item
                                                  .trim()
                                                  .substring(1);
                                                return (
                                                  <li
                                                    key={subItems}
                                                    className="mb-2 ml-5 list-[circle]"
                                                  >
                                                    {subItems}
                                                  </li>
                                                );
                                              } else {
                                                return (
                                                  <li
                                                    key={item}
                                                    className="mb-2"
                                                  >
                                                    {item}
                                                  </li>
                                                );
                                              }
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                              {syndromeData?.otherPossibleAssociations && (
                                <div className=" ">
                                  <h2 className="text-lg font-bold text-blue-600 mt-2 mb-2 uppercase">
                                    Other Possible Associations
                                  </h2>

                                  {syndromeData?.otherPossibleAssociations?.Data?.map(
                                    (finding) => (
                                      <div key={finding.id} className="mb-4">
                                        <h4 className="font-medium mb-2">
                                          {finding.Title}
                                        </h4>
                                        <ul className="list-disc ml-10 text-gray-700 ">
                                          {finding.Value.split("|").map(
                                            (item) => {
                                              if (item.trim().startsWith("@")) {
                                                let subItems = item
                                                  .trim()
                                                  .substring(1);
                                                return (
                                                  <li
                                                    key={subItems}
                                                    className="mb-2 ml-5 list-[circle]"
                                                  >
                                                    {subItems}
                                                  </li>
                                                );
                                              } else {
                                                return (
                                                  <li
                                                    key={item}
                                                    className="mb-2"
                                                  >
                                                    {item}
                                                  </li>
                                                );
                                              }
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}

                              {syndromeData?.heredity && (
                                <div className=" ">
                                  <h2 className="text-lg font-bold text-blue-600 mt-2 mb-2 uppercase">
                                    Heredity
                                  </h2>
                                  <div className="mb-4">
                                    {syndromeData?.heredity}
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="animate-pulse">
                              <div className="h-5 bg-gray-200 rounded w-64 mb-4"></div>

                              <div className="flex flex-col space-y-3">
                                <div className="h-4 bg-gray-200 rounded w-full"></div>
                                <div className="h-4 bg-gray-200 rounded w-full"></div>
                                <div className="h-4 bg-gray-200 rounded w-full"></div>
                              </div>

                              <div className="h-5 mt-10 bg-gray-200 rounded w-96 mb-4"></div>
                              <div className="h-5 bg-gray-200 rounded w-36 mb-4"></div>
                              <div className="flex flex-col space-y-3">
                                <div className="h-4 bg-gray-200 rounded w-44"></div>
                                <div className="h-4 bg-gray-200 rounded w-52"></div>
                                <div className="h-4 bg-gray-200 rounded w-80"></div>
                                <div className="h-4 bg-gray-200 rounded w-44"></div>
                                <div className="h-4 bg-gray-200 rounded w-80"></div>
                              </div>

                              <div className="h-5 mt-6 bg-gray-200 rounded w-36 mb-4"></div>
                              <div className="flex flex-col space-y-3">
                                <div className="h-4 bg-gray-200 rounded w-80"></div>
                                <div className="h-4 bg-gray-200 rounded w-44"></div>
                                <div className="h-4 bg-gray-200 rounded w-52"></div>
                                <div className="h-4 bg-gray-200 rounded w-64"></div>
                                <div className="h-4 bg-gray-200 rounded w-36"></div>
                                <div className="h-4 bg-gray-200 rounded w-72"></div>
                                <div className="h-4 bg-gray-200 rounded w-80"></div>
                                <div className="h-4 bg-gray-200 rounded w-44"></div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {diagnosesInfo && (
                        <>
                          <div className="sticky">
                            <h3 className="pb-2 text-2xl font-bold text-gray-900 ">
                              Syndrome
                            </h3>
                            <h3 className="pb-2 text-xl font-medium text-gray-900 border-b">
                              {diagnosesInfo.name.charAt(0).toUpperCase() +
                                diagnosesInfo.name.slice(1)}
                            </h3>
                          </div>
                          <div className="overflow-y-auto h-[37rem]">
                            <div className=" ">
                              <h2 className="text-lg font-bold text-blue-600 mt-2 mb-2 uppercase">
                                Description And Definition
                              </h2>
                              <p
                                className="text-gray-800 mb-4"
                                dangerouslySetInnerHTML={{
                                  __html: ventriculomegalyDdescription,
                                }}
                              ></p>
                            </div>
                            {diagnosesInfo?.abnormalities.length !== 0 ? (
                              diagnosesInfo?.abnormalities.map(
                                (abnormality) => (
                                  <div
                                    className="  pb-5"
                                    key={abnormality.name}
                                  >
                                    <h2 className="text-lg font-bold text-blue-600 mt-2 mb-2 uppercase">
                                      {abnormality.name}
                                    </h2>
                                    <ul className="text-gray-800 mb-4 ml-5">
                                      {abnormality?.syndromes?.map(
                                        (item, index) => (
                                          <li
                                            key={item.name}
                                            className="list-disc"
                                          >
                                            <span className="mr-1">
                                              {item.name}
                                            </span>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                )
                              )
                            ) : (
                              <div className=" ">
                                <p className=" mx-2 flex justify-center items-center">
                                  No data was found
                                </p>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="sticky">
                        <h3 className="pb-2 text-2xl font-bold text-gray-900 ">
                          Diagnoses
                        </h3>
                        <h3 className="pb-2 text-xl font-medium text-gray-900 border-b">
                          {hit.name.charAt(0).toUpperCase() + hit.name.slice(1)}
                        </h3>
                      </div>
                      <div className="overflow-y-auto h-[37rem]">
                        {hit.name == "Nuchal Fold Thickening" && (
                          <div className=" ">
                            <h2 className="text-lg font-bold text-blue-600 mb-2 uppercase">
                              Description And Definition
                            </h2>
                            <p
                              className="text-gray-800 mb-4"
                              dangerouslySetInnerHTML={{
                                __html: nuchalFoldThickeningDescription,
                              }}
                            ></p>
                          </div>
                        )}
                        {hit?.abnormalities.length !== 0 ? (
                          hit?.abnormalities.map((abnormality) => (
                            <>
                              <div
                                className="  pb-5"
                                key={abnormality.name}
                              >
                                <h2 className="text-lg font-bold text-blue-600 mt-2 mb-2 uppercase">
                                  {abnormality.name}
                                </h2>
                                <ul className="text-gray-800 mb-4 ml-5">
                                  {abnormality?.syndromes?.map(
                                    (item, index) => (
                                      <li key={item.name} className="list-disc">
                                        <span
                                          className={`mr-1 ${
                                            textData.NOTCLICKABLESYNDROMES.includes(
                                              item.name
                                            )
                                              ? "cursor-not-allowed"
                                              : "cursor-pointer"
                                          }`}
                                          onClick={
                                            textData.NOTCLICKABLESYNDROMES.includes(
                                              item.name
                                            )
                                              ? null
                                              : onItemClick
                                          }
                                        >
                                          {item.name}
                                        </span>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </>
                          ))
                        ) : (
                          <div className=" ">
                            <p className=" mx-2 flex justify-center items-center">
                              No data was found
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {isSyndromeInfo && (
                  <div className="flex-1 pt-[1.1rem]">
                    {diagnosesInfo ? (
                      <>
                        <div className="sticky">
                          <h3 className="pb-2 text-2xl font-bold text-gray-900 ">
                            Syndrome
                          </h3>
                          <h3 className="pb-2 text-xl font-medium text-gray-900 border-b">
                            {diagnosesInfo.name.charAt(0).toUpperCase() +
                              diagnosesInfo.name.slice(1)}
                          </h3>
                        </div>
                        <div className="overflow-y-auto h-[37rem]">
                          <div className=" ">
                            <h2 className="text-lg font-bold text-blue-600 mt-2 mb-2 uppercase">
                              Description And Definition
                            </h2>
                            <p
                              className="text-gray-800 mb-4"
                              dangerouslySetInnerHTML={{
                                __html: ventriculomegalyDdescription,
                              }}
                            ></p>
                          </div>
                          {diagnosesInfo?.abnormalities.length !== 0 ? (
                            diagnosesInfo?.abnormalities.map((abnormality) => (
                              <div
                                className="  pb-5"
                                key={abnormality.name}
                              >
                                <h2 className="text-lg font-bold text-blue-600 mt-2 mb-2 uppercase">
                                  {abnormality.name}
                                </h2>
                                <ul className="text-gray-800 mb-4 ml-5">
                                  {abnormality?.syndromes?.map(
                                    (item, index) => (
                                      <li key={item.name} className="list-disc">
                                        <span className="mr-1">
                                          {item.name}
                                        </span>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            ))
                          ) : (
                            <div className=" ">
                              <p className=" mx-2 flex justify-center items-center">
                                No data was found
                              </p>
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="sticky border-b">
                          <h3 className="pb-2 pl-5 text-2xl font-bold text-gray-900">
                            Syndrome
                          </h3>
                          <div className="flex justify-between">
                            {syndromeInfo?.name && (
                              <h3
                                title={
                                  syndromeInfo?.name.charAt(0).toUpperCase() +
                                  syndromeInfo?.name.slice(1)
                                }
                                className="pb-2 pl-5 text-xl font-medium text-gray-900 w-[calc(100vw-80vw)] overflow-hidden whitespace-nowrap overflow-ellipsis"
                              >
                                {syndromeInfo?.name.charAt(0).toUpperCase() +
                                  syndromeInfo?.name.slice(1)}
                              </h3>
                            )}
                            <button
                              className="bg-transparent h-[2.2rem] mr-[2rem] hover:bg-blue-600 text-blue-600 font-semibold hover:text-white py-1 px-4 border border-blue-500 hover:border-transparent rounded"
                              type="button"
                              onClick={onInvestigationClick}
                            >
                              Investigation
                            </button>
                          </div>
                        </div>
                        <>
                          <div className="p-5 overflow-y-auto max-h-[37rem]">
                            {syndromeInfo?.descriptionAndDefinition && (
                              <div className=" ">
                                <h2 className="text-lg font-bold text-blue-600 mb-2 uppercase">
                                  Description And Definition
                                </h2>
                                <p
                                  className="text-gray-800 mb-4"
                                  dangerouslySetInnerHTML={{
                                    __html: descriptionSyndrome,
                                  }}
                                ></p>
                              </div>
                            )}

                            {syndromeInfo?.abnormalitiesDetectableByUltrasound && (
                              <div className=" ">
                                <h2 className="text-lg font-bold text-blue-600 mt-2 mb-2 uppercase">
                                  Ultrasound can also show :
                                </h2>

                                {syndromeInfo?.abnormalitiesDetectableByUltrasound?.Data?.map(
                                  (finding) => (
                                    <div key={finding.id} className="mb-4">
                                      <h4 className="font-medium mb-2">
                                        {finding.Title}
                                      </h4>
                                      <ul className="list-disc ml-10 text-gray-700 ">
                                        {finding.Value.split("|").map(
                                          (item) => {
                                            if (item.trim().startsWith("@")) {
                                              let subItems = item
                                                .trim()
                                                .substring(1);
                                              return (
                                                <li
                                                  key={subItems}
                                                  className="mb-2 ml-5 list-[circle]"
                                                >
                                                  {subItems}
                                                </li>
                                              );
                                            } else {
                                              return (
                                                <li key={item} className="mb-2">
                                                  {item}
                                                </li>
                                              );
                                            }
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                            {syndromeInfo?.otherPossibleAssociations && (
                              <div className=" ">
                                <h2 className="text-lg font-bold text-blue-600 mt-2 mb-2 uppercase">
                                  Other Possible Findings
                                </h2>

                                {syndromeInfo?.otherPossibleAssociations?.Data?.map(
                                  (finding) => (
                                    <div key={finding.id} className="mb-4">
                                      <h4 className="font-medium mb-2">
                                        {finding.Title}
                                      </h4>
                                      <ul className="list-disc ml-10 text-gray-700 ">
                                        {finding.Value.split("|").map(
                                          (item) => {
                                            if (item.trim().startsWith("@")) {
                                              let subItems = item
                                                .trim()
                                                .substring(1);
                                              return (
                                                <li
                                                  key={subItems}
                                                  className="mb-2 ml-5 list-[circle]"
                                                >
                                                  {subItems}
                                                </li>
                                              );
                                            } else {
                                              return (
                                                <li key={item} className="mb-2">
                                                  {item}
                                                </li>
                                              );
                                            }
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {syndromeInfo?.heredity && (
                              <div className=" ">
                                <h2 className="text-lg font-bold text-blue-600 mt-2 mb-2 uppercase">
                                  Heredity
                                </h2>
                                <div className="mb-4">
                                  {syndromeInfo?.heredity}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      </>
                    )}
                  </div>
                )}
                {investigationInfo && (
                  <div className="flex-1 pt-[3.4rem]">
                    <div className="sticky border-b">
                      <h3 className="pb-2 pl-5 text-2xl font-medium text-gray-900">
                        {"Investigation".charAt(0).toUpperCase() +
                          "Investigation".slice(1)}
                      </h3>
                    </div>

                    <>
                      <div className="p-5">
                        {syndromeInfo?.investigations?.data.length !== 0 ? (
                          <div className="overflow-y-auto max-h-[37rem]">
                            {syndromeInfo?.investigations?.data.map(
                              (item, index) => (
                                <li
                                  key={item.attributes.name}
                                  className="list-disc"
                                >
                                  <span className="mr-1">
                                    {item.attributes.name}
                                  </span>
                                </li>
                              )
                            )}
                          </div>
                        ) : (
                          <div className=" ">
                            <p className=" mx-2 flex justify-center items-center">
                              No Specifix Investagition
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                )}
                {syndromeInvestigationInfo && (
                  <div className="flex-1 pt-[1.1rem]">
                    <div className="sticky border-b">
                      <h3 className="pb-2 pl-5 text-2xl font-medium text-gray-900">
                        {"Investigation".charAt(0).toUpperCase() +
                          "Investigation".slice(1)}
                      </h3>
                    </div>

                    <>
                      <div className="p-5">
                        {syndromeData?.investigations?.data.length !== 0 ? (
                          <div className="overflow-y-auto max-h-[37rem]">
                            {syndromeData?.investigations?.data.map(
                              (item, index) => (
                                <li
                                  key={item.attributes.name}
                                  className="list-disc"
                                >
                                  <span className="mr-1">
                                    {item.attributes.name}
                                  </span>
                                </li>
                              )
                            )}
                          </div>
                        ) : (
                          <div className=" ">
                            <p className=" mx-2 flex justify-center items-center">
                              No Specifix Investagition
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                )}
              </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Checkbox;
