import React, { useState, useEffect } from "react";
import AppStateProvider from "./Context/AppStateProvider";
import containers from "./Context/state";
import Routes from "./Routes";
import "./assets/fonts/fonts.css";
import "./assets/styles/common.css";
import useAuthState from "./Context/state/useAuthState";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const { removeFreeTrial } = useAuthState("app");

  useEffect(() => {
    checkScreenWidth();
    removeFreeTrial();
    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const checkScreenWidth = () => {
    setIsMobile(window.innerWidth < 768); // Adjust the threshold as needed
  };

  return (
    <AppStateProvider containers={containers}>
      <Routes />
      {isMobile && (
        <div
          id="default-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-slate-100 bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            {/* Modal content */}
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
              </div>
              <div className="relative z-50 w-11/12 max-w-md p-6 bg-white rounded-lg">
                <div className="mb-4 text-center">
                  <p className="text-lg font-semibold text-gray-800">
                    Please open in desktop or tablet for the best experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AppStateProvider>
  );
}

export default App;
