import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import algoliasearch from "algoliasearch/lite";
import client from "algoliasearch";
import { InstantSearch, SearchBox, useHits } from "react-instantsearch";
// import "./search.css";
import Checkbox from "./Checkbox";
import axios from "axios";
import useMainState from "../Context/state/useMainState";
import { getUniqueValues } from "../utils/myFunction";

const searchClient = algoliasearch(
  "5OSPL9PYUS",
  "40cfaf12f496d5dd77226a26499cf09e"
);

function sortHits(data) {
  data.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
  return data;
}

const CustomHits = ({
  checkedItems,
  setCheckedItems,
  selectedDiagnoses,
  firstClicked,
  setIsOpenModel,
  isOpenModel,
}) => {
  const { hits, results } = useHits();

  const { allPossibleSyndromes: filterdSyndromes } =
    hits && findUniqueSyndromes(hits, selectedDiagnoses);

  const allSyndromes = hits && findAllSyndromes(hits);

  sortHits(allSyndromes);

  const matchedSyndromes = hits && findUniqueMatchingSyndromes(hits);

  if (results.nbHits === 0 && !results.query) {
    return (
      <div className="animate-pulse">
        <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
        <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
        <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
        <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
        <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
        <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
        <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
        <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
        <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
        <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
      </div>
    );
  }

  return (
    <>
      {firstClicked.isSyndromeSelected ? (
        <>
          {results.query === "" ? (
            allSyndromes.map((hit) => {
              return (
                <Checkbox
                  isSyndrome={true}
                  key={hit.objectID}
                  hit={hit}
                  checked={checkedItems[hit.objectID]?.checked || false}
                  onCheckboxChange={() =>
                    setCheckedItems((prevCheckedItems) => ({
                      ...prevCheckedItems,
                      [hit.objectID]: {
                        ...prevCheckedItems[hit.objectID],
                        checked: !prevCheckedItems[hit.objectID]?.checked,
                        name: hit.name,
                      },
                    }))
                  }
                  setIsOpenModel={setIsOpenModel}
                  isOpenModel={isOpenModel}
                />
              );
            })
          ) : matchedSyndromes.length === 0 ? (
            <div className="mx-2 flex justify-center items-center">
              No Syndromes found
            </div>
          ) : (
            matchedSyndromes.map((hit) => {
              return (
                <Checkbox
                  isSyndrome={true}
                  key={hit.objectID}
                  hit={hit}
                  checked={checkedItems[hit.objectID]?.checked || false}
                  onCheckboxChange={() =>
                    setCheckedItems((prevCheckedItems) => ({
                      ...prevCheckedItems,
                      [hit.objectID]: {
                        ...prevCheckedItems[hit.objectID],
                        checked: !prevCheckedItems[hit.objectID]?.checked,
                        name: hit.name,
                      },
                    }))
                  }
                  setIsOpenModel={setIsOpenModel}
                  isOpenModel={isOpenModel}
                />
              );
            })
          )}
        </>
      ) : (
        <>
          {filterdSyndromes.map((hit) => {
            return (
              <Checkbox
                isSyndrome={true}
                key={hit.objectID}
                hit={hit}
                checked={checkedItems[hit.objectID]?.checked || false}
                onCheckboxChange={() =>
                  setCheckedItems((prevCheckedItems) => ({
                    ...prevCheckedItems,
                    [hit.objectID]: {
                      ...prevCheckedItems[hit.objectID],
                      checked: !prevCheckedItems[hit.objectID]?.checked,
                      name: hit.name,
                    },
                  }))
                }
                setIsOpenModel={setIsOpenModel}
                isOpenModel={isOpenModel}
              />
            );
          })}
        </>
      )}
    </>
  );
};

function findAllSyndromes(data) {
  const syndromes = data.flatMap((diagnosis) => {
    return diagnosis.abnormalities.flatMap((abnormality) => {
      return abnormality.syndromes.map((syndrome) => {
        return {
          name: syndrome.name,
          objectID: syndrome.id,
          descriptionAndDefinition: syndrome.descriptionAndDefinition,
          slug: syndrome.slug,
          bodyParts: syndrome.bodyParts,
        };
      });
    });
  });

  return syndromes.filter((syndrome, index, self) => {
    return self.findIndex((s) => s.name === syndrome.name) === index;
  });
}

const findUniqueMatchingSyndromes = (data) => {
  const getFullMatchSyndromeNames = (data) => {
    return data
      ?.flatMap((diagnosis) =>
        diagnosis?._highlightResult?.abnormalities?.flatMap((abnormality) =>
          abnormality?.syndromes?.flatMap((syndrome) => {
            if (syndrome?.name?.matchLevel === "full") {
              return syndrome?.name?.value.replace(/<\/?mark>/g, "");
            }
          })
        )
      )
      .filter(Boolean);
  };

  const uniqueSyndromeNames = [...new Set(getFullMatchSyndromeNames(data))];

  const filteredAndUniqueData = data
    ?.flatMap((diagnosis) =>
      diagnosis?.abnormalities?.flatMap((abnormality) =>
        abnormality?.syndromes?.filter((syndrome) =>
          uniqueSyndromeNames?.some((name) => syndrome?.name?.includes(name))
        )
      )
    )
    .filter(Boolean);

  const uniqueObjectsByName = {};

  for (const syndromeObject of filteredAndUniqueData) {
    if (syndromeObject && syndromeObject.name) {
      uniqueObjectsByName[syndromeObject.name] = {
        name: syndromeObject.name,
        slug: syndromeObject.slug,
        objectID: syndromeObject.id,
        descriptionAndDefinition: syndromeObject.descriptionAndDefinition,
      };
    }
  }
  return Object.values(uniqueObjectsByName);
};

function findUniqueSyndromes(data, searchNames) {
  const allSyndromesMap = new Map();
  const commonSyndromesMap = new Map();

  searchNames.forEach((searchName) => {
    const matches = data.find((item) => item.name === searchName);
    if (matches) {
      matches.abnormalities.forEach((type) => {
        type.syndromes.forEach((syndrome) => {
          const syndromeKey = `${syndrome.name}-${syndrome.id}`;
          allSyndromesMap.set(syndromeKey, {
            name: syndrome.name,
            objectID: syndrome.id,
            slug: syndrome.slug,
          });
          if (
            searchNames.every((name) =>
              data
                .find((item) => item.name === name)
                ?.abnormalities.some((t) =>
                  t.syndromes.some((s) => s.name === syndrome.name)
                )
            )
          ) {
            commonSyndromesMap.set(syndromeKey, {
              name: syndrome.name,
              objectID: syndrome.id,
              slug: syndrome.slug,
            });
          }
        });
      });
    }
  });

  const commonSyndromes = [...commonSyndromesMap.values()];
  const allPossibleSyndromes = [...allSyndromesMap.values()];

  const filteredAllPossibleSyndromes = getUniqueValues(
    commonSyndromes,
    allPossibleSyndromes
  );

  return {
    commonSyndromes: commonSyndromes.reverse(),
    allPossibleSyndromes: filteredAllPossibleSyndromes.reverse(),
  };
}

function SidebarSyndromes2({
  checkedItems,
  setCheckedItems,
  checkedDiagnoses,
  setFirstClicked,
  firstClicked,
  isSyndromeModelOpen,
  setIsSyndromeModelOpen,
  bodyPart,
  setBodyPart,
}) {
  const backdropRef = useRef(null);
  const [query, setQuery] = useState("");
  const [allSyndromes, setAllSyndromes] = useState([]);
  const [commonSyndromes, setCommonSyndromes] = useState([]);
  const [hitsData, setHitsData] = useState([]);
  const { syndromeModel, setSyndromeModel } = useMainState("app");
  const [isOpenModel, setIsOpenModel] = useState({
    isOpenSidebar: false,
    isOpenDiscription: false,
  });
  const [isShowLoader, setIsShowLoader] = useState(true);
  const selectedCheckBoxRef = useRef(null);
  const customHitsRef = useRef(null);
  const API_KEY = "d62ae06c780fca089f35d07a53760ef4";
  const APPLICATION_ID = "5OSPL9PYUS";
  const url = `https://${APPLICATION_ID}-dsn.algolia.net/1/indexes/diagnoses`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsShowLoader(true);
        const response = await axios.get(url, {
          headers: {
            "X-Algolia-API-Key": API_KEY,
            "X-Algolia-Application-Id": APPLICATION_ID,
          },
        });
        setHitsData(response.data.hits);
        setSyndromeModel({ ...syndromeModel, isOpenSidebar: true });
        setIsShowLoader(false);
      } catch (error) {
        console.error(error);
        setIsShowLoader(false);
      }
    };

    fetchData();
  }, [checkedDiagnoses]);

  useEffect(() => {
    if (selectedCheckBoxRef.current && customHitsRef.current) {
      const selectedCheckBoxHeight = selectedCheckBoxRef.current.offsetHeight;
      const headerHeight = 68;
      const searchBoxHeight = 46;

      const sumHeight = selectedCheckBoxHeight + headerHeight + searchBoxHeight;

      customHitsRef.current.style.maxHeight = `calc(100vh - ${sumHeight}px)`;
    }
  });
  const selectedCheckBox = Object.values(checkedItems).filter(
    (item) => item.checked
  );

  const uncheckName = (data, nameToUncheck) => {
    const updated = { ...data };

    Object.values(updated).forEach((item) => {
      if (item.name === nameToUncheck) {
        item.checked = false;
      }
    });

    return updated;
  };

  const selectedDiagnoses = useMemo(() => {
    return Object.values(checkedDiagnoses)
      .filter((item) => item.checked)
      .map((item) => item.name);
  }, [checkedDiagnoses]);

  useEffect(() => {
    const { commonSyndromes, allPossibleSyndromes } =
      hitsData && findUniqueSyndromes(hitsData, selectedDiagnoses);
    setAllSyndromes(allPossibleSyndromes);
    setCommonSyndromes(commonSyndromes);
  }, [hitsData, selectedDiagnoses]);

  const filteredSyndromes =
    allSyndromes &&
    allSyndromes?.filter((syndrome) => {
      return syndrome?.name?.toLowerCase().includes(query.toLowerCase());
    });

  const handleButtonClickModelOpen = (e) => {
    e.preventDefault();
    setIsSyndromeModelOpen(true);

    if (firstClicked.isDiagnosesSelected) {
      setFirstClicked({
        ...firstClicked,
        isSyndromeSelected: false,
      });
    } else {
      setFirstClicked({
        ...firstClicked,
        isSyndromeSelected: true,
      });
    }

    const algoliaClient = client(
      "5OSPL9PYUS",
      "d62ae06c780fca089f35d07a53760ef4"
    );
    let index = algoliaClient.initIndex("diagnoses");
    index.setSettings({
      searchableAttributes: ["abnormalities.syndromes.name"],
    });
  };

  const handleButtonClickModelClose = (e) => {
    e.preventDefault();
    setIsSyndromeModelOpen(false);
    setBodyPart(bodyPart);

    if (firstClicked.isSyndromeSelected) {
      if (Object.entries(checkedItems).length === 0) {
        setFirstClicked({
          ...firstClicked,
          isSyndromeSelected: false,
        });
      }
    }
    document.body.style.overflowY = "scroll";
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const setCheckedItem = useCallback(
    (hit) => {
      setCheckedItems((prevCheckedItems) => ({
        ...prevCheckedItems,
        [hit.objectID]: {
          ...prevCheckedItems[hit.objectID],
          checked: !prevCheckedItems[hit.objectID]?.checked,
          name: hit.name,
        },
      }));
    },
    [setCheckedItems]
  );

  const showSyndromes = (hit) => {
    return (
      <Checkbox
        isSyndrome={true}
        key={hit.objectID}
        hit={hit}
        checked={checkedItems[hit.objectID]?.checked || false}
        onCheckboxChange={() => setCheckedItem(hit)}
      />
    );
  };

  const renderSyndromes = () => {
    const filteredAllSyndromes = allSyndromes.filter((syndrome) =>
      syndrome.name.toLowerCase().includes(query.toLowerCase())
    );
    const filteredCommonSyndromes = commonSyndromes.filter((syndrome) =>
      syndrome.name.toLowerCase().includes(query.toLowerCase())
    );

    return (
      <div>
        {filteredCommonSyndromes.length > 0 && (
          <div className="mb-4">
            <h3 className="block font-medium mb-2 ml-4 text-xl font-poppinsMedium text-[#03254c] tex-center min-w-[200px]">
              Common Syndromes
            </h3>
            {filteredCommonSyndromes.map((syndrome) => showSyndromes(syndrome))}
          </div>
        )}
        {filteredAllSyndromes.length > 0 && (
          <div className="mb-4">
            <h3 className="block font-medium mb-2 ml-4 text-xl font-poppinsMedium text-[#03254c] tex-center min-w-[200px]">
              All Possible Syndromes
            </h3>
            {filteredAllSyndromes.map((syndrome) => showSyndromes(syndrome))}
          </div>
        )}
        {filteredCommonSyndromes.length === 0 &&
          filteredAllSyndromes.length === 0 && (
            <p className="mx-2 flex justify-center items-center">
              No Syndromes found
            </p>
          )}
      </div>
    );
  };

  const handleClick = (event) => {
    if (backdropRef.current && !backdropRef.current.contains(event.target)) {
      handleButtonClickModelClose(event);
    }
  };

  return (
    <>
      <button
        type="button"
        className="px-4 py-3 font-poppinsMedium text-white border rounded bg-[#03254C]"
        onClick={handleButtonClickModelOpen}
      >
        Search Syndromes
      </button>

      {isSyndromeModelOpen &&
        ((document.body.style.overflowY = "hidden"),
        (
          <div
            className="fixed inset-0 bg-black bg-opacity-25 z-10"
            onClick={handleClick}
          >
            <div
              className="fixed top-0 right-0 h-full laptop-big:w-1/2 w-3/4 bg-white shadow-xl transition-transform overflow-y-auto"
              ref={backdropRef}
            >
              <InstantSearch searchClient={searchClient} indexName="diagnoses">
                {firstClicked.isDiagnosesSelected ? (
                  <div>
                    <div className="flex items-center py-3">
                      <button
                        className="px-4 py-2"
                        onClick={handleButtonClickModelClose}
                      >
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                      </button>
                      <p className="font-poppinsMedium text-2xl w-full text-center">
                        Syndrome
                      </p>
                    </div>
                    <label
                      htmlFor="default-search"
                      className="mb-2 text-sm font-medium text-gray-900 sr-only"
                    >
                      Search
                    </label>
                    <div className="fixed laptop-big:w-1/2 w-3/4 bg-white">
                      <div className="absolute top-[15px] left-7 flex items-center pointer-events-none">
                        <svg
                          className="w-5 h-5 text-gray-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="search"
                        id="default-search"
                        name="search syndrome"
                        className="w-[97%] border border-[#bcbcbc] rounded-md py-2 pr-2 pl-10 text-base focus:border-[#909090] mx-[14px] mb-2"
                        value={query}
                        onChange={handleSearch}
                        placeholder="Search Syndromes Here"
                        required
                      />

                      <div
                        className="flex flex-wrap mx-2 mb-2 gap-2 test02"
                        ref={selectedCheckBoxRef}
                      >
                        {selectedCheckBox.map((item) => (
                          <div
                            key={item.name}
                            className="flex items-center bg-[#e9e9e9] text-black px-2 py-2 rounded-3xl pl-3"
                          >
                            <span className="">{item.name}</span>
                            <span
                              className="cursor-pointer px-1.5 pl-2 py-1"
                              onClick={() => {
                                setCheckedItems((prev) => {
                                  const result = uncheckName(prev, item.name);
                                  return result;
                                });
                              }}
                            >
                              <svg
                                class="w-2.5 h-2.5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        ))}
                      </div>
                      <div
                        className="pb-10 overflow-y-auto h-[calc(100vh_-_90px)]"
                        ref={customHitsRef}
                      >
                        {isShowLoader && (
                          <div className="animate-pulse">
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                          </div>
                        )}
                        {!isShowLoader && renderSyndromes()}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="fixed bg-white laptop-big:w-1/2 w-3/4">
                      <div className="flex items-center py-4">
                        <button
                          className="px-4 py-2"
                          onClick={handleButtonClickModelClose}
                        >
                          <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                          </svg>
                        </button>
                        <p className="font-poppinsMedium text-2xl w-full text-center">
                          Syndrome
                        </p>
                      </div>

                      <label htmlFor="default-search" className="hidden">
                        Search
                      </label>

                      <div className="absolute left-2 flex items-center pl-3 pointer-events-none pt-[15px]">
                        <svg
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <SearchBox placeholder="Search Syndromes Here" />
                      <div
                        className="flex flex-wrap mx-2 mb-2 gap-2"
                        ref={selectedCheckBoxRef}
                      >
                        {selectedCheckBox.map((item) => (
                          <div
                            key={item.name}
                            className="flex items-center bg-[#e9e9e9] text-black px-2 py-2 rounded-3xl pl-3"
                          >
                            <span className="">{item.name}</span>
                            <span
                              className="cursor-pointer px-1.5 pl-2 py-1"
                              onClick={() => {
                                setCheckedItems((prev) => {
                                  const result = uncheckName(prev, item.name);
                                  return result;
                                });
                              }}
                            >
                              <svg
                                className="w-2.5 h-2.5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                              </svg>
                            </span>
                          </div>
                        ))}
                      </div>
                      <div
                        className="pb-10 overflow-y-auto h-[calc(100vh_-_90px)]"
                        ref={customHitsRef}
                      >
                        <CustomHits
                          checkedItems={checkedItems}
                          setCheckedItems={setCheckedItems}
                          selectedDiagnoses={selectedDiagnoses}
                          firstClicked={firstClicked}
                          setIsOpenModel={setSyndromeModel}
                          isOpenModel={syndromeModel}
                        />
                      </div>
                    </div>
                  </>
                )}
              </InstantSearch>
            </div>
          </div>
        ))}
    </>
  );
}
export default SidebarSyndromes2;
